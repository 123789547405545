import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function CustomerManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO title="" description="" />
      <SolutionLayout
        data={{
          slug: 'core-crm',
          hero: {
            image: require("./../assets/CRM-screen.png"),
            titleSecond: "Core crm",
            subtitle: "Cloud based customer management system",
            content:
              "Core CRM is a complete Customer Relationship Management software that is a great fit for almost any company, freelancer or many other uses.",
          },
          section: {
            title: "How can Core crm help you?",
            subtitle:
              "With its clean and modern design, Core CRM can help you look more professional to your customers and help improve business performance at the same time.",
          },
          imageSection1: {
            image: require("./../assets/crm-invoice-1.png"),
            title: "Enhance your customer experience",
            content:
              "Manage and invoice projects with the powerful Project Management Feature.Build professional, great looking estimates and invoices.Keep track of leads in one place and easily follow their progress.",
          },
          imageSection2: {
            image: require("./../assets/crm-view-2].png"),
            title: "Manage your sales from a simple to use dashboard",
            content:
              "Manage all your sales processes, deals and customers from a simple easy to use dashboard.Issue offer letters and track payments with ease.",
          },
        }}
      />
    </Layout>
  )
}
