import React from "react"

import { OtherSolutions } from "./other-solution"
import { PurpleHeroSection } from "./purple-hero-sections"
import { TalkToUs } from "./talk-to-us"

export const SolutionLayout = ({ data }) => {
  return (
    <div>
      <PurpleHeroSection
        bgpurple={!data.hero.bgColor}
        bgColor={data.hero.bgColor}
        image={data.hero.image}
        titleFirst={data.hero.titleFirst}
        titleSecond={data.hero.titleSecond}
        subtitle={data.hero.subtitle}
        content={data.hero.content}
      />
      <div className="container px-5 md:px-12 pt-5 pb-20 md:py-20">
        <h4
          data-aos="fade-left"
          className="text-3xl md:text-4xl font-black leading-tight text-center text-pgray mt-4"
        >
          {data.section.title}
        </h4>

        <h6
          data-aos="zoom-in"
          className="text-pgray text-justify mt-2 md:text-center"
        >
          {data.section.subtitle}
        </h6>
      </div>
      {data.imageSection1 && (
        <div className="container">
          <div
            className={`flex flex-col-reverse md:grid ${
              data.imageSection1.overlap
                ? "md:grid-cols-2 lg:grid-cols-12"
                : "md:grid-cols-5 px-5 mb-20 lg:px-16 md:gap-10"
            } grid-rows-1 items-center`}
          >
            <div
              className={`${
                data.imageSection1.overlap
                  ? "lg:col-span-2 lg:col-start-2 lg:col-end-6 z-10 row-start-1"
                  : "md:col-span-2"
              } lg:p-8 mt-5 md:mt-0`}
            >
              <Card v={data.imageSection1} />
            </div>
            <div
              className={`${
                data.imageSection1.overlap
                  ? "lg:col-start-4 lg:col-end-12 row-start-1 z-0 row-end-1 lg:col-span-3 lg:pl-8"
                  : "md:col-span-3 bg-black"
              }`}
            >
              <img className="" src={data.imageSection1.image} />
            </div>
          </div>
        </div>
      )}
      {data.imageSection2 && (
        <div className="container md:px-16 px-5">
          <div className="grid gap-6 md:grid-cols-5 items-center grid-cols-1">
            <div className="md:col-span-3">
              <img className="" src={data.imageSection2.image} />
            </div>
            <div className="lg:mr-10 md:col-span-2">
              <Card v={data.imageSection2} />
            </div>
          </div>
        </div>
      )}
      <TalkToUs slug={data.slug} />
      {!!data.information?.length && (
        <div className="container mt-10 md:mb-32 px-5">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:mx-32">
            {data.information.map(info => (
              <Card v={info} />
            ))}
          </div>
        </div>
      )}
      {data.more_information}
      <OtherSolutions slug={data.slug} />
    </div>
  )
}

export function Card({ v }) {
  return (
    <div className="shadow bg-white p-8">
      <h6 className="text-pgray text-center md:text-left text-2xl font-black mb-4">
        {v.title}
      </h6>
      <p className="text-justify md:text-left leading-relaxed">{v.content}</p>
    </div>
  )
}
