import React from "react"

export function PurpleHeroSection({
  bgpurple = true,
  bgColor,
  image,
  imageComponent,
  titleFirst,
  titleSecond,
  subtitle,
  content,
}) {
  return (
    <section
      className={`${
        bgpurple
          ? "bg-ppurple text-white"
          : bgColor
          ? bgColor
          : "bg-plightpink text-pgray"
      } bg-clip`}
    >
      <div className="container pb-5 md:pb-10 px-5 md:px-12 pt-10 md:pt-0 flex flex-col md:flex-row items-center">
        <div data-aos="fade-right" className="w-full md:w-5/12">
          <h1 className="text-4xl">
            {titleFirst} <span className="font-black">{titleSecond}</span>
          </h1>
          <h2
            className={`${
              bgpurple ? "" : bgColor ? bgColor : "text-black"
            } font-black text-2xl mb-6`}
          >
            {subtitle}
          </h2>
          <p className="text-sm">{content}</p>
        </div>
        {image && (
          <img
            data-aos="fade-left"
            alt={""}
            className="ml-auto w-full md:w-6/12 max-h-screen pt-10 pb-20 "
            src={image}
          />
        )}
        {imageComponent}
      </div>
    </section>
  )
}
